<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

      <section class="md-section" id="sitemap">
        <div class="container">
          <h1 class="text-center" style="padding-bottom: 1rem"><strong>Preguntas Frecuentes</strong></h1>

          <div class="invisible-box">
             <Accordion :activeIndex="0">
              <AccordionTab header="¿Como ver el estado de mis pedidos?">
                  <p>Si tienes una cuenta en el sitio web o bien creaste una al realizar tu pedido. Puedes ver todos los detalles de tus pedidos y su estado en 'Mi Cuenta' > 'Pedidos'</p>
              </AccordionTab>
              <AccordionTab header="No encuentro el producto que busco">
                  <p>Si no encuentras el producto que estas buscando puedes contanctar con nosotros enviando un email a info@novoinox.com o llamando al 000 00 00 00 y con gusto te ayudaremos a encontrar el producto que buscas.</p>
              </AccordionTab>
              <AccordionTab header="¿Cómo solicitar una devolución?">
                  <p>Para solicitar una devolución puede hacerlo desde nuestro formulario de 'Contacto' o enviando un email a info@novoinox.com.</p>
              </AccordionTab>
              <AccordionTab header="¿Cómo crear una cuenta?">
                  <p>Para crear una cuenta dirígete a 'Iniciar Sesión' y a continuacion haz click en 'Registrarse', completa el formualrio de Registro correctamente haz click en 'Registrarse'.</p>
              </AccordionTab>
              <AccordionTab header="¿Cómo cambiar mi contraseña?">
                  <p>Con la sesión iniciada dirígete a 'Mi Cuenta' > 'Cambiar contraseña', debes introducir la contraseña actual y la nueva contraseña que desees. No olvides guardar los cambios para que estos sean efectivos.</p>
              </AccordionTab>
              <AccordionTab header="¿Cómo cambiar mis datos?">
                  <p>Con la sesión iniciada dirígete a 'Mi Cuenta' > 'Detalles de la cuenta', y edita los datos de tu cuenta que necesites. No olvides guardar los cambios para que estos sean efectivos.</p>
              </AccordionTab>
              <AccordionTab header="He olvidado la contraseña de mi cuenta ¿Cómo puedo recuperarla?">
                  <p>Para crear una cuenta dirígete a 'Iniciar Sesión' y a continuacion haz click en '¿Olvidaste tu contraseña?', a continuación introduce tu correo electrónico y recibiras en este un enlace donde podrás establecer una nueva contraseña.</p>
              </AccordionTab>
              <AccordionTab header="¿Puedo solicitar una factura con datos de facturación diferentes a los de mi cuenta?">
                  <p>Para solicitar una devolución puede hacerlo desde nuestro formulario de 'Contacto' o enviando un email a info@novoinox.com.</p>
              </AccordionTab>
          </Accordion>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    Accordion,
    AccordionTab
  },
});
</script>

<style scoped>

</style>